import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import Grid from "@material-ui/core/Grid";
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import withWidth, { isWidthDown } from "@material-ui/core/withWidth";
import { withRouter } from "react-router-dom";
import Backdrop from "@material-ui/core/Backdrop";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import { PHONE_NUMBER_KEY } from "Constants";
import { useDispatch, useSelector } from "react-redux";
import { resendOtpApi, verifyPhoneNumberApi } from "api/userApi";
import { getStoreInfo, loginWithTokenAction } from "redux/actions/loginActions";

const OtpDialog = ({ classes, width, history }) => {
  const userId = useSelector((state) => state.loginData._id);
  const storeInfo = useSelector((state) => state.loginData.storeInfo);
  const dispatch = useDispatch();
  const phoneNumber = localStorage.getItem(PHONE_NUMBER_KEY);
  const [otp, setOtp] = useState("");
  const [small, setSmall] = useState(false);
  const [error, setError] = useState(false);
  const [height, setheight] = useState(window.innerHeight);
  const [errMsg, setErrMsg] = useState("");
  const [success, setSuccess] = useState("");
  const isValidated = useSelector((state) => state.loginData.isMobileVerified);

  window.addEventListener("resize", () => {
    setheight(window.innerHeight);
  });

  useEffect(() => {
    if (isWidthDown("xs", width)) {
      setSmall(true);
    } else {
      setSmall(false);
    }
  }, [width]);

  useEffect(() => {
    if (isValidated) {
      history.push("/");
    }
  }, []);

  const submit = async (e) => {
    e.preventDefault();
    verifyPhoneNumberApi({ _id: userId, otp })
      .then((res) => dispatch(loginWithTokenAction(storeInfo._id, history)))
      .catch((e) => {
        setErrMsg(e.message || "something went wrong");
        setError(true);
      });
  };

  useEffect(() => {
    if (isValidated) {
      console.log("isValidated called");
      history.push("/");
    }
  }, [isValidated]);

  const handleResendOtp = () => {
    resendOtpApi({ _id: userId, phoneNumber })
      .then((res) => setSuccess(res.resendPhoneNumberOtp.message))
      .catch((err) => {
        setError(true);
        setErrMsg(err.message || "something went wrong");
      });
  };

  return (
    <Backdrop
      className={classes.backdrop}
      fullScreen={small && true}
      maxWidth="sm"
      open={true}
      style={{ overflow: "scroll", flexDirection: "column" }}
    >
      <Grid style={{ width: "27%", minWidth: 380 }} spacing={2}>
        <Grid
          style={{
            height: `${(height < 600 ? height / 15000 : -5) + 7500 / height}%`,
          }}
        />
        <div style={{ height: 20 }} />
        <Grid style={{ backgroundColor: "#fff", borderRadius: "10px" }}>
          <div style={{ height: 20 }} />
          <Typography
            variant="h5"
            style={{
              fontWeight: "bold",
              textAlign: "center",
              borderBottom: "2px solid #eee",
              paddingBottom: "10px",
            }}
          >
            Sign Up
          </Typography>
          <Box padding={4}>
            <Typography variant="h6" style={{ fontWeight: "bold" }}>
              Otp Verification
            </Typography>
            <Typography style={{ fontSize: 14, marginTop: "1rem" }}>
              We sent you a code to verify your number
            </Typography>
            <Typography style={{ fontSize: 14, margin: "10px 0" }}>
              Code sent to
              <span style={{ fontSize: 16, paddingLeft: 10 }}>
                {localStorage.getItem(PHONE_NUMBER_KEY)}
              </span>
            </Typography>

            <form onSubmit={submit}>
              <Box display="flex" justifyContent="center">
                <Grid
                  style={{
                    display: "flex",
                    margin: "7vh 10px",
                    justifyContent: "space-around",
                    alignItems: "center",
                    maxWidth: "20rem",
                  }}
                >
                  {[...Array(4).keys()].map((i, k) => (
                    <TextField
                      id={`otp${i}`}
                      inputProps={{
                        maxLength: 1,
                        style: {
                          textAlign: "center",
                        },
                      }}
                      style={{
                        margin: 5,
                      }}
                      key={k}
                      value={otp.split("")[i] || ""}
                      onChange={(e) => {
                        // if ((e.target.value >= '0' && e.target.value <= '9') || e.target.value === '') {
                        let tempOtp = otp.split("");
                        for (let oi = 0; oi < 4; oi++) {
                          if (!tempOtp[oi] || tempOtp[oi] === "") {
                            tempOtp[oi] = "";
                          }
                        }
                        // tempOtp[i] = e.target.value;
                        let otpString = "";
                        for (let oti = 0; oti < 4; oti++) {
                          otpString = otpString.concat(
                            tempOtp[oti] === "" ? " " : tempOtp[oti]
                          );
                        }
                        tempOtp.splice(
                          i,
                          e.target.value === "" ? 1 : 0,
                          e.target.value.split("")
                        );
                        setOtp(tempOtp.join(""));
                        // setOtp(otpString);
                        if (e.target.value === "") {
                          if (i > 0) {
                            document.getElementById(`otp${i - 1}`).focus();
                          } else {
                            document.getElementById(`otp${i}`).blur();
                          }
                        } else {
                          if (i < 3) {
                            document.getElementById(`otp${i + 1}`).focus();
                          } else {
                            document.getElementById(`otp${i}`).blur();
                          }
                        }
                        // }
                      }}
                    />
                  ))}
                </Grid>
              </Box>

              <Typography
                color="primary"
                onClick={handleResendOtp}
                style={{ textAlign: "right", cursor: "pointer" }}
              >
                Resend OTP
              </Typography>

              <Button
                type="submit"
                className={classes.btn}
                variant="contained"
                color="primary"
                disableElevation
              >
                Verify
              </Button>

              <Typography
                component="div"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  margin: "10px 0",
                }}
              >
                <Typography
                  as="h6"
                  style={{ color: "#333", fontWeight: "bold" }}
                >
                  Already have an account?{" "}
                </Typography>

                <Typography
                  as="h6"
                  color="primary"
                  onClick={() => {
                    history.push("/login");
                  }}
                  style={{
                    fontWeight: "bold",
                    marginLeft: 5,
                    cursor: "pointer",
                  }}
                >
                  Login
                </Typography>
              </Typography>
            </form>
            <div style={{ height: 20 }} />
          </Box>
          <Snackbar
            open={error}
            autoHideDuration={3000}
            onClose={() => setError(false)}
          >
            <Alert severity="error" onClose={() => setError(false)}>
              {errMsg}
            </Alert>
          </Snackbar>

          <Snackbar
            open={success !== ""}
            autoHideDuration={3000}
            onClose={() => setSuccess("")}
          >
            <Alert severity="error" onClose={() => setSuccess("")}>
              {success}
            </Alert>
          </Snackbar>
        </Grid>
        <Grid
          style={{
            height: `${(height < 600 ? height / 15000 : -5) + 7500 / height}%`,
          }}
        />
      </Grid>
    </Backdrop>
  );
};

const styles = (theme) => ({
  form: {
    width: "28vw",
    height: "89vh",
    // minWidth:'27rem',
    background: "white",
    margin: "3vh",
    display: "flex",
    flexDirection: "column",
    // alignItems:'center',
    justifyContent: "space-around",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "3rem",
      height: "70vh",
      marginTop: "10vh",
    },
  },
  boxes: {
    position: "relative",
  },
  input: {
    outline: "none",
    color: "#999",
    [theme.breakpoints.down("sm")]: {
      width: "70% !important",
      margin: "10px 20px !important",
    },
  },
  forgot: {
    [theme.breakpoints.down("xs")]: {
      textAlign: "center !important",
    },
  },
  label: {
    // marginLeft:'20px'
    paddingBottom: "20px",
  },
  clear: {
    color: "#515151",
    background: "#eee",
    padding: 5,
    position: "relative",
    zIndex: 1,
    "&:hover": {
      background: "#eee",
    },
    [theme.breakpoints.down("xs")]: {
      // position:'absolute',
      // right:'6vw'
    },
  },
  btn: {
    width: "70%",
    height: "6.5vh",
    margin: "16px 12%",
    borderRadius: 24,
    fontWeight: "bold",
    "&:hover": {
      background: theme.palette.primary.main,
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    background: "rgba(0,0,0,0.75)",
  },
});

export default withRouter(withWidth()(withStyles(styles)(OtpDialog)));
