import AsyncStorage from "@react-native-community/async-storage";
import ReduxThunk from "redux-thunk";
import { persistReducer, persistStore } from "redux-persist";
import rootReducers from "./reducers";
import { applyMiddleware, compose, createStore } from "redux";
import logger from "redux-logger";

const persistConfig = {
  key: "root",
  storage: AsyncStorage,
};

const middleware = [ReduxThunk, logger];

const persistedReducer = persistReducer(persistConfig, rootReducers);
export const store = createStore(
  persistedReducer,
  compose(applyMiddleware(...middleware))
);
export const persister = persistStore(store);
