export const storeInfoString = `
_id
storeName
storeNameInArabic
category
highlight
highlightStatus
avgPreparation
avgPreparationUnit
policies
aboutUs
instagram
twitter
whatsapp
snapchat
pickupOrder
minimumOrder
minimumOrderAmount
logo
banner
bannerSecond
productLayout
themeId
fontFamily
fontCDNURL
primaryThemeColourCode
secondrythemeColourCode
headerColourCode
footerColourCode
highlightColourCode
backgroundColourCode
primaryDomain {
  domainName
  provider
  renewedOn
  connected
}
secondaryDomain {
  domainName
  provider
  renewedOn
  connected
}
enableOrderTrackingForCustomers
enableOwnDelivery
enableCashOnDelivery
costOfDelivery
googleAnalytics
facebookPixel
isLunched
seo {
  slug
  metaTittle
  metaDescription
  canonical
  ogUrl
  ogType
  ogSiteName
}
registrationProgress {
  signup
  storeDetails
  storeTheme
  domain
  shipping
  storeLocation
  payment
  products
  totalProgress
}
storeLocation{
  _id
  storeName
  cityId
  block
  street
  avenue
  building
  phoneNumber
  additionalDetails
}
salesTarget
orderTarget
`;
