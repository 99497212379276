import {
  LOG_OUT_REQUEST,
  LOGIN_ERROR,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  SET_STORE_REQUEST,
  SET_STORE_FAILED,
  SET_STORE_DETAILS,
  RESET,
} from "../../constants/userConstant";

const initialState = {
  key: "",
  userData: {},
  storeInfo: {},
  loading: false,
  isSignOut: false,
  userToken: null,
  error: false,
};

const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_REQUEST: {
      return {
        ...state,
        loading: true,
        error: false,
      };
    }

    case LOGIN_SUCCESS: {
      return {
        ...state,
        ...action.userData.userInfo,
        storeInfo: action.userData.storeInfo,
        userData: action.userData,
        loading: false,
        userToken: action.userData.userInfo.accessToken,
        error: false,
      };
    }

    case LOG_OUT_REQUEST: {
      return {
        ...state,
        userData: "",
        loading: false,
        userToken: "",
        error: false,
      };
    }

    case SET_STORE_REQUEST: {
      return {
        ...state,
        loading: true,
        error: false,
      };
    }

    case SET_STORE_FAILED: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    case SET_STORE_DETAILS: {
      return {
        ...state,
        userData: {
          ...action.data,
          userInfo: state.userData.userInfo,
        },
        storeInfo: action.data.storeInfo,
        loading: false,
        error: false,
      };
    }

    case LOGIN_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    case RESET: {
      return initialState;
    }

    default:
      return state;
  }
};

export default loginReducer;
