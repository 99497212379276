import navbar from './navbar.json';
import storedetails from './storedetails.json';
import sidebar from './sidebar.json';
import Domains from './Domains.json';
import shipping from './shipping.json';
import location from './location.json';
import payment from './payment.json';

export default {
  ...navbar,
  ...storedetails,
  ...sidebar,
  ...Domains,
  ...shipping,
  ...location,
  ...payment,
}