import {
  LOGIN_ERROR,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOG_OUT_REQUEST,
  SET_STORE_DETAILS,
  SET_STORE_REQUEST,
  SET_STORE_FAILED,
  TOTAL_SALES_TARGET,
  TOTAL_ORDER_TARGET,
} from "../../constants/userConstant";
import {
  LoginApi,
  loginWithAccessTokenApi,
  signUpApi,
  logoutApi,
} from "../../api/userApi";
import { GetStoreApi } from "../../api/storeApi";
import {
  ACCESS_TOKEN_KEY,
  PHONE_NUMBER_KEY,
  STORE_ID_KEY,
} from "../../Constants";
import AsyncStorage from "@react-native-community/async-storage";

function request() {
  return {
    type: LOGIN_REQUEST,
  };
}

function success(userData) {
  return {
    type: LOGIN_SUCCESS,
    userData,
  };
}

export const salestarget = (userData) => {
  return {
    type: TOTAL_SALES_TARGET,
    userData,
  };
};

export const orderstarget = (userData) => {
  return {
    type: TOTAL_ORDER_TARGET,
    userData,
  };
};

function failure(error) {
  return {
    type: LOGIN_ERROR,
    error,
  };
}

function logOut() {
  return {
    type: LOG_OUT_REQUEST,
  };
}

export function loginUserAction(userPayload, history) {
  return (dispatch) => {
    dispatch(request());
    LoginApi(userPayload)
      .then((data) => {
        // console.log({ loginUserAction: data.login });
        if (data.login) {
          localStorage.setItem(
            ACCESS_TOKEN_KEY,
            data.login.userInfo.accessToken
          );
          dispatch(success(data.login));
          history.push("/");
        } else {
          dispatch(failure("login Failed"));
        }
      })
      .catch((err) => dispatch(failure(err)));
  };
}

export function signUpUserAction(userPayload, history) {
  return (dispatch) => {
    dispatch(request());
    signUpApi(userPayload)
      .then((data) => {
        // console.log(data.signUp);
        localStorage.removeItem("SIGNUP_FORM");

        localStorage.setItem(
          ACCESS_TOKEN_KEY,
          data.signUp.userInfo.accessToken
        );
        dispatch(success(data.signUp));
        //   localStorage.setItem(ACCESS_TOKEN_KEY, res.signUp.userInfo.accessToken);
        //localStorage.setItem(USER_ID_KEY, data.signUp.userInfo._id);
        localStorage.setItem(
          PHONE_NUMBER_KEY,
          data.signUp.userInfo.phoneNumber
        );
        localStorage.setItem(STORE_ID_KEY, data.signUp.storeInfo._id);
        history.push("/verification");
      })
      .catch((err) => {
        console.log("error response is ", err);
        dispatch(failure(err));
      });
  };
}

export function logOutCalled() {
  return (dispatch) => {
    logoutApi();
    AsyncStorage.removeItem("persist:root");
    localStorage.clear();
    AsyncStorage.clear();
    dispatch(logOut);
  };
}

export function getStoreInfo(id) {
  return (dispatch) => {
    dispatch({ type: SET_STORE_REQUEST });
    GetStoreApi(id)
      .then((res) => {
        dispatch({
          type: SET_STORE_DETAILS,
          data: {
            storeInfo: res.storeDetails,
          },
        });
      })
      .catch((e) => {
        dispatch({
          type: SET_STORE_FAILED,
          error: "Store Information failed",
        });
      });
  };
}

export function updateStoreData(storeDetails) {
  return (dispatch) => {
    dispatch({
      type: SET_STORE_DETAILS,
      data: {
        storeInfo: storeDetails,
      },
    });
  };
}

export function loginWithTokenAction(id, history) {
  return (dispatch) => {
    dispatch(request());
    loginWithAccessTokenApi(id)
      .then((data) => {
        if (data.loginWithAccessToken) {
          localStorage.setItem(
            ACCESS_TOKEN_KEY,
            data.loginWithAccessToken.userInfo.accessToken
          );
          dispatch(success(data.loginWithAccessToken));
          dispatch(
            salestarget(data.loginWithAccessToken.storeInfo.salesTarget)
          );
          dispatch(
            orderstarget(data.loginWithAccessToken.storeInfo.orderTarget)
          );
          if (history) {
            history.push("/home");
          }
        } else {
          dispatch(failure("login Failed"));
        }
      })
      .catch((err) => dispatch(failure(err)));
  };
}
