import navbar from './navbar.json';
import storedetails from './storedetails.json';
import sidebar from './sidebar.json';
import Domains from './Domains.json';
import shipping from './shipping.json';
import location from './location.json';
import payment from './payment.json';
import orders from './orders.json';
import customer from './customer.json';
import settings from './settings.json';
import products from './products.json';
import coupon from './coupon.json'
import category from './category.json'
export default {
  ...navbar,
  ...storedetails,
  ...sidebar,
  ...Domains,
  ...shipping,
  ...location,
  ...payment,
  ...orders,
  ...customer,
  ...settings,
  ...products,
  ...coupon,
  ...category,
}