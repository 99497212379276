import React from "react";
import ReactDOM from "react-dom";
import Root from "./Root";
import * as serviceWorker from "./serviceWorker";

import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import "./i18n.js"

ReactDOM.render(
  <Provider store={store}>
      <BrowserRouter>
        <Root />
      </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);

serviceWorker.unregister();
