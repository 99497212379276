import React from "react";
import "./style.css";

import {
  MuiThemeProvider,
  createMuiTheme,
  responsiveFontSizes,
} from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";

const theme = createMuiTheme({
  palette: {
    // type:'dark',
    background: {
      default: "#fff",
    },
    primary: {
      main: "#ffc845",
      red: "#ca3a3a",
      dashboardBackground: "#f7f7f7",
      iconBtnBackground: "#fef8e9",
      lightBackground: "rgba(247,247,247,0.8)",
      gray: "#686b73",
      lightgray: "#babbbf",
      secondlightgray: "rgba(151, 151, 151, 0.2000000029802322)",
      orange: "#ffb476",
      lightOrange: "#fff3e9",
      lightgreen: "#d5ecc7",
      green: "#78c24a",
      labelGreen: "#58b41f",
      label: "#9c9ea3",
      textGrey: "#52565E",
    },
    secondary: {
      main: "#ffc845",
    },
  },
  //to change typography simple change din pro regular to din pro font which are locally defined
  typography: {
    useNextVariants: true,
    fontFamily: "'DIN Pro Regular', sans-serif",
    fontSize: 14,
    subtitle1: {
      fontSize: 12,
    },
    subtitle2: {
      // fontFamily:'"DIN Pro Medium", sans-serif',
      fontSize: 14,
      color: "rgba(0,0,0,0.5)",
    },
    body2: {
      fontFamily: '"DIN Pro Medium", sans-serif',
      //fontSize: 18
    },
    h5: {
      fontFamily: '"DIN Pro Bold", sans-serif',
    },
  },
});

function styledRoot(Component) {
  function Root(props) {
    return (
      <MuiThemeProvider theme={responsiveFontSizes(theme)}>
        <CssBaseline />
        <Component {...props} />
      </MuiThemeProvider>
    );
  }

  return Root;
}

export default styledRoot;
