import { ACCESS_TOKEN_KEY } from "../Constants";

export const checkAuthentication = () => {
  let isAuthenticated = false;
  const token = localStorage.getItem(ACCESS_TOKEN_KEY);
  console.log(token);
  if (token && token !== "") {
    isAuthenticated = true;
  }
  return isAuthenticated;
};
