import {
  TOTAL_SALES_TARGET,
  TOTAL_ORDER_TARGET,
  RETURN_TARGET,
} from "../../constants/userConstant";

const initialState = {
  targetSales: "",
  targetOrders: "",
};

const TargetReducers = (state = initialState, action) => {
  switch (action.type) {
    case TOTAL_SALES_TARGET: {
      return {
        ...state,
        targetSales: action.userData,
      };
    }

    case TOTAL_ORDER_TARGET: {
      return {
        ...state,
        targetOrders: action.userData,
      };
    }

    case RETURN_TARGET: {
      return {
        ...state,
      };
    }

    default:
      return state;
  }
};

export default TargetReducers;
