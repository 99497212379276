import { GRAPH_QL } from "helpers/graphql-helpers";
import { storeInfoString } from "./commonRes";

export const GetStoreApi = (id) => {
  const query_string = `
  query{
    storeDetails(id:"${id}") {
     ${storeInfoString}
    }
  }
    `;
  return GRAPH_QL(query_string);
};

export const UpdateStoreApi = (payload) => {
  const query_string = `
    mutation{
      storeDetails(
        _id:"${payload._id}"
        avgPreparation:${payload.avgPreparation}
        avgPreparationUnit:"mins"
        policies:"${payload.policies
          .replace(/"/g, "'")
          .replace(/\r?\n|\r/g, "")}"
        aboutUs:"${payload.aboutUs.replace(/"/g, "'").replace(/\r?\n|\r/g, "")}"
        instagram:"${payload.instagram}"
        twitter:"${payload.twitter}"
        whatsapp:"${payload.whatsapp}"
        snapchat:"${payload.snapchat}"
        pickupOrder:${payload.pickupOrder}
        minimumOrder:${payload.minimumOrder}
        minimumOrderAmount:${payload.minimumOrderAmount}
      ) {
        ${storeInfoString}
      }
    }`;
  return GRAPH_QL(query_string);
};

export const addCategory = (payload) => {
  const add_string = `mutation
  {
    addCategory(
      name: "${payload.name}",
      nameInArabic: "${payload.nameInArabic}",
      storeId: "${payload.storeId}",
      type:  "${payload.type}",
      image: "${payload.image}",
    ){
      name,
      nameInArabic,
      _id,
      storeId,
      image,
    }
  }`;
  return GRAPH_QL(add_string);
};

export const getCategoryWithType = (storeId, type, search) => {
  const searchString = `
  query{
    getCategories(
      storeId:"${storeId}",
      type:"${type}",
      search:"${search}"
    )
    {
      list{
        _id
        orderInView
        name
        nameInArabic
        storeId
        image
        subCategories{
          _id
          name
          nameInArabic
          storeId
          image
          totalProduct
          isBlocked
        }
        totalProduct
        isBlocked
      }
    
    }
  }
  `;
  return GRAPH_QL(searchString);
};

export const deleteCategory = (categoryId) => {
  const deleteString = `
  mutation{
    deleteCategory(
      _id:"${categoryId}"
    ) {
      message
    }
  }`;
  return GRAPH_QL(deleteString);
};

export const updateOrderOfCategoriesAPI = (storeId, categoryIdsAndOrders) => {
  const body = `
  mutation{
    updateOrderOfCategories(
      storeId: "${storeId}",
      categoryIdsAndOrders: ${categoryIdsAndOrders}
    ){
      message
    }
  }`;
  return GRAPH_QL(body);
};

export const addGlobalOptions = (payload) => {
  const globalOptionData = `
  mutation{
    addAttribute(
      name:"${payload.name}",
      storeId:"${payload.storeId}",
      type: "single",
      priceForSubCategory:${payload.priceForSubCategory}
      mandatoryField:false,
      minRange:0,
      maxRange:0
    ){
      _id,
      name,
    }
  }`;
  return GRAPH_QL(globalOptionData);
};

export const getGlobalOption = (storeId) => {
  const getOptionBody = `
query{
  getAttributes(storeId:"${storeId}"){
    list{
      name,
      _id
    }
  }
}`;

  return GRAPH_QL(getOptionBody);
};

export const deleteGlobalOption = (id) => {
  const body = `
mutation{
  deleteAttribute(_id: "${id}") {
    message
  }
}
`;
  return GRAPH_QL(body);
};

export const addSubCategory = (name, nameInArabic, storeId, parentId) => {
  const body = `
  mutation{
    addSubCategory(
      name:"${name}",
      storeId:"${storeId}",
      parentId:"${parentId}"
    ) {
      _id,
      name
    }
  }
  `;
  return GRAPH_QL(body);
};

export const addSizeChart = (storeId, image) => {
  const body = `
  mutation{
    addSizeChart(
      storeId: "${storeId}",
      image: "${image}"
    ) {
      _id,
      image
    }
  }
  `;
  return GRAPH_QL(body);
};

export const getSizeChartList = (storeId) => {
  const body = `
  query{
    getSizeChart(storeId: "${storeId}") {
      list {
        _id
        image
      }
    }
  }
  `;
  return GRAPH_QL(body);
};

export const deleteSizeChart = (id) => {
  const body = `
  mutation{
    deleteSizeChart(_id: "${id}"){
      message
    }
  }
  `;
  return GRAPH_QL(body);
};

export const updateThemeApi = (themeData) => {
  console.log(themeData);
  const body = `
  mutation {
    storeTheme(
      _id: "${themeData.storeId}"
      logo: "${themeData.logo}"
      banner: [${optionsToString(themeData.banner)}]
      bannerSecond: []
      productLayout: ${themeData.layout}
      themeId: "${themeData.themeId}"
      primaryThemeColourCode: "${themeData.primaryThemeColourCode}"
      secondrythemeColourCode: "${themeData.secondrythemeColourCode}"
      headerColourCode: "${themeData.headerColourCode}"
      footerColourCode: "${themeData.footerColourCode}"
      highlightColourCode: "${themeData.highlightColourCode}"
      backgroundColourCode: "${themeData.backgroundColourCode}"
      fontFamily: "${themeData.fontFamily}"
      fontCDNURL: "${themeData.fontCDNURL}"
    ) {
      ${storeInfoString}
    }
  }`;
  console.log(body);
  return GRAPH_QL(body);
};

export const getThemeDataApi = (storeId) => {
  const data = `
  query {
    storeDetails(id: "${storeId}") {
      logo
      banner
      bannerSecond
      productLayout
      themeId
      primaryThemeColourCode
      secondrythemeColourCode
      headerColourCode
      footerColourCode
      highlightColourCode
      backgroundColourCode
      fontFamily
    }
  }
  `;
  return GRAPH_QL(data);
};

export const updateLogoApi = (storeId, logo) => {
  const body = `
  mutation{
    storeLogo(
      _id: "${storeId}",
      logo:"${logo}"
    ){
      _id
      logo
    }
  }
  `;
  return GRAPH_QL(body);
};

export const getThemesApi = () => {
  const query_string = `
  query {
  getThemes {
    list {
      _id
      name
      price
      type
      image
    }
  }
}`;
  return GRAPH_QL(query_string);
};

export const updateSEOApi = ({
  _id,
  slug,
  metaDescription,
  metaTittle,
  canonical,
  ogSiteName,
  ogType,
  ogUrl,
}) => {
  const query_string = `
    mutation {
  updateSeo(
    _id: "${_id}"
    slug: "${slug}"
    metaDescription: "${metaDescription}"
    metaTittle: "${metaTittle}"
    canonical: "${canonical}"
    ogSiteName: "${ogSiteName}"
    ogType: "${ogType}"
    ogUrl: "${ogUrl}"
  ) {
    seo {
      slug
      metaTittle
      metaDescription
      canonical
      ogUrl
      ogType
      ogSiteName
    }
  }
}`;

  return GRAPH_QL(query_string);
};

export const storeShippingApi = ({
  storeId,
  enableOrderTrackingForCustomers,
  enableCashOnDelivery,
  enableOwnDelivery,
  costOfDelivery,
  localShippingCompany,
  internationalShippingCompany,
}) => {
  console.log(storeId);
  const body = `
                mutation{
                  storeShipping(
                    _id: "${storeId}"
                    enableOrderTrackingForCustomers: ${enableOrderTrackingForCustomers}
                    enableOwnDelivery: ${enableOwnDelivery}
                    enableCashOnDelivery: ${enableCashOnDelivery}
                    costOfDelivery: ${costOfDelivery}
                    localShippingCompany: ${localShippingCompany}
                    internationalShippingCompany: ${internationalShippingCompany}
                  ) {
                    ${storeInfoString}
                  }
                }
              `;
  console.log(body);
  return GRAPH_QL(body);
};

const optionsToString = (option) => {
  let str = "";
  for (let i = 0; i < option.length; i++) {
    str += `"${option[i]}",`;
  }
  return str;
};

export const launchStoreApi = (storeId, isLaunched) => {
  const query_string = `
  mutation {
    launchStore(storeId: "${storeId}", isLaunched: ${isLaunched}) {
      message
    }
  }
  `;
  return GRAPH_QL(query_string);
};

export const createLocationApi = ({
  _id,
  storeName,
  countryId,
  cityId,
  stateId,
  block,
  street,
  avenue,
  building,
  phoneNumber,
  additionalDetails,
  latitude,
  longitude,
  mashkoorBranchId,
}) => {
  const query_string = `
  mutation {
    storeLocation(
      _id: "${_id}"
      storeName: "${storeName}"
      block: "${block}"
      street: "${street}"
      avenue: "${avenue}"
      building: "${building}"
      phoneNumber: "${phoneNumber}"
      additionalDetails: "${additionalDetails}"
      latitude: "${latitude}"
      longitude: "${longitude}"
      countryId: "${countryId}"
      cityId: "${cityId}"
      stateId: "${stateId}"
      mashkoorBranchId: "${mashkoorBranchId}"
    ) {
     ${storeInfoString}
    }
  }
  `;
  return GRAPH_QL(query_string);
};

export const deleteStoreLocationApi = ({ storeId, locationId }) => {
  const query_string = `
  mutation {
    deleteStoreLocation(storeId: "${storeId}", locationId: "${locationId}") {
      ${storeInfoString}
    }
  }`;
  return GRAPH_QL(query_string);
};

export const updateLocationApi = ({
  storeId,
  locationId,
  storeName,
  countryId,
  cityId,
  stateId,
  block,
  street,
  avenue,
  building,
  phoneNumber,
  additionalDetails,
  latitude,
  longitude,
  mashkoorBranchId,
}) => {
  const query_string = `
  mutation {
    updateStoreLocation(
      storeId: "${storeId}"
      locationId: "${locationId}"
      storeName: "${storeName}"
      countryId: "${countryId}"
      cityId: "${cityId}"
      stateId: "${stateId}"
      block: "${block}"
      street: "${street}"
      avenue: "${avenue}"
      building: "${building}"
      phoneNumber: "${phoneNumber}"
      additionalDetails: "${additionalDetails}"
      latitude: "${latitude}"
      longitude: "${longitude}"
      mashkoorBranchId: "${mashkoorBranchId}"
    ) {
      ${storeInfoString}
    }
  }
  
  `;
  console.log(query_string);
  return GRAPH_QL(query_string);
};

export const getStoreLocationAPI = (storeId) => {
  const query_string = `
    query{
      getStoreLocation(storeId:"${storeId}") {
        _id,
        storeName,
        countryId, 
        cityId,
        stateId,
        block,
        street,
        avenue,
        building,
        phoneNumber,
        additionalDetails,
        mashkoorBranchId
      }
    }
  `;
  return GRAPH_QL(query_string);
};
