export const LOGIN = "LOGIN";
export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const LOG_OUT_REQUEST = "LOG_OUT_REQUEST";
export const SET_STORE_REQUEST = "SET_STORE_REQUEST";
export const SET_STORE_DETAILS = "SET_STORE_DETAILS";
export const SET_STORE_FAILED = "SET_STORE_FAILED";
export const LOG_OUT = "LOG_OUT";
export const RESET = "RESET";
export const TOTAL_SALES_TARGET = "TOTAL_SALES_TARGET";
export const TOTAL_ORDER_TARGET = "TOTAL_ORDER_TARGET";
export const RETURN_TARGET = "RETURN_TARGET";
export const REACT_APP_SECRET_KEY = "6Lcb83wkAAAAAIUuEG9fC6086yut4GFWHV0PhwEM";
export const REACT_APP_SITE_KEY = "6Lcb83wkAAAAAJeXFwG_1GOUxroGgUt9Z_EAxE37";
