import axios from "axios";
import { ACCESS_TOKEN_KEY, LOG, BASE_URL } from "Constants";

export const GRAPH_QL = (query_string) => {
  return new Promise((resolve, reject) => {
    const token = localStorage.getItem(ACCESS_TOKEN_KEY);
    console.log(query_string);
    axios({
      url: BASE_URL,
      headers: {
        accessToken: `Bearer ${token}`,
      },
      method: "post",
      data: { query: query_string },
    })
      .then((res) => {
        if (res.data.errors !== undefined) {
          if (res.data.errors[0].extensions.code === 401) {
            localStorage.clear();
            window.location.href = "/";
            //  history.push('/');
            console.log("true");
          }
          console.log("error is 123 ", res.data.errors[0].message);
          console.log("error is 123 ", res.data.errors);
          reject(res.data.errors[0]);
        }

        if (res.data.data) {
          // console.log(res)
          resolve(res.data.data);
        } else {
          console.log("res is", res);
          reject(res);
        }
      })
      .catch((e) => handleError(e, reject));
  });
};

function handleError(e, reject) {
  if (LOG) {
    console.log(e.response);
  }
  reject(e);
}
