import LoginReducers from "./LoginReducers";
import { LOG_OUT } from "../../constants/userConstant";
import AsyncStorage from "@react-native-community/async-storage";
import { combineReducers } from "redux";
import TargetReducers from "./TargetReducers";

const appReducer = combineReducers({
  loginData: LoginReducers,
  targetData: TargetReducers,
});

//clean the state when user sign out
export const rootReducers = (state = {}, action) => {
  if (action.type === LOG_OUT) {
    AsyncStorage.removeItem("persist:root");
    state = undefined;
  }
  return appReducer(state, action);
};

export default appReducer;
