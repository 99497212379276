import { GRAPH_QL } from "helpers/graphql-helpers";
import { storeInfoString } from "./commonRes";

export const signUpApi = (payload) => {
  const query_string = `
  mutation{
    signUp(payload:{
      name:"${payload.name}"
      email:"${payload.email}"
      password:"${payload.password}"
      phoneNumber:"${payload.phoneNumber}"
      language:"english"
      deviceToken:"${payload.deviceToken}"
      deviceType:"${payload.deviceType}"
      storeName:"${payload.storeName}"
      storeNameInArabic:"${payload.storeNameInArabic}"
      category:"${payload.category}"
      businessType: "${payload.businessType}"
    }) {
        userInfo {
          _id
          name
          email
          phoneNumber
          accessToken
          language
          role
          isMobileVerified
          isMailVerified
        }
        storeInfo {
         ${storeInfoString}
        }
      }
    }
  `;
  console.log(query_string)
  return GRAPH_QL(query_string);
};

export const LoginApi = (payload) => {
  const query_string = `
mutation {
  login(
    email:"${payload.email}"
    password:"${payload.password}"
    deviceToken:"${payload.deviceToken}"
    deviceType:"${payload.deviceType}"
  ) {
    userInfo {
      _id
      name
      email
      phoneNumber
      accessToken
      role
      language
      isMobileVerified
      isMailVerified
    }
    storeInfo {
     ${storeInfoString}
    }
  }
}

  `;
  return GRAPH_QL(query_string);
};

export const verifyPhoneNumberApi = (payload) => {
  const query_string = `
    mutation{
    phoneNumberVerification(
      _id:"${payload._id}"
      otp:${payload.otp}
    ) {
      message
    }
  }`;
  return GRAPH_QL(query_string);
};

export const resendOtpApi = (payload) => {
  const query_string = `
    mutation{
    resendPhoneNumberOtp(
      _id:"${payload._id}"
      phoneNumber:"${payload.phoneNumber}"
    ) {
      message
    }
  }`;
  return GRAPH_QL(query_string);
};

export const forgotPasswordpApi = (email) => {
  const query_string = `
  mutation{
    forgetPassword(
      email:"${email}"
    ) {
      message
    }
  }`;
  return GRAPH_QL(query_string);
};

export const resetPasswordpApi = (
  password,
  confirmPassword,
  passwordResetToken
) => {
  const query_string = `
  mutation{
    resetPassword(
      password:"${password}"
      confirmPassword:"${confirmPassword}"
      passwordResetToken:"${passwordResetToken}"
    ) {
      message
    }
  }`;
  return GRAPH_QL(query_string);
};

export const logoutApi = () => {
  const query_string = `
  query{
        logout
  }`;
  return GRAPH_QL(query_string);
};

export const getBusinessCategories = () => {
  const body = `
  query{
    getBusinessCategories{
      list{
        _id
        name
        nameInArabic
      }
    }
  }
  `;

  return GRAPH_QL(body);
};

export const checkAvailabilityOfEmailApi = (email) => {
  const body = `query{checkAvailabilityOfEmail(email:"${email}") }`;
  return GRAPH_QL(body);
};

export const updateUserProfile = ({
  storeId,
  userId,
  category,
  name,
  phoneNumber,
}) => {
  const body = `
  mutation{
    updateProfile(
      storeId:"${storeId}",
      userId:"${userId}",
      category:"${category}",
      name:"${name}",
      phoneNumber:"${phoneNumber}"
    ) {message}
  }
  `;
  return GRAPH_QL(body);
};

export const getTermsAndConditionApi = ({ type }) => {
  const body = `
  query{
  getTermCondition(type:"${type}") {
    _id
    text
    textInArabic
  }
}`;
  return GRAPH_QL(body);
};

export const getSEOApi = (storeId) => {
  const query_string = `
  query {
  loginWithAccessToken(storeId: "${storeId}") {
    storeInfo {
      seo {
        slug
        metaTittle
        metaDescription
        canonical
        ogUrl
        ogType
        ogSiteName
      }
    }
  }
}`;
  return GRAPH_QL(query_string);
};

export const loginWithAccessTokenApi = (storeId) => {
  const query_string = `
  query {
    loginWithAccessToken(storeId: "${storeId}") {
        userInfo {
          _id
          name
          email
          phoneNumber
          accessToken
          language
          role
          isMobileVerified
          isMailVerified
        }
        storeInfo {
         ${storeInfoString}
         subscription{
          planId
          status
          approvalTime
        }
        }
      }
    }
  `;
  return GRAPH_QL(query_string);
};
